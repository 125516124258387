document.addEventListener("DOMContentLoaded", function () {
  const showMoreLinks = document.querySelectorAll(".show-more");

  showMoreLinks.forEach(showMoreLink => {
    const itemList = showMoreLink.closest(".toggle-container").previousElementSibling;
    const hideMoreLink = showMoreLink.nextElementSibling;
    const hiddenItems = itemList.querySelectorAll(".item:nth-child(n+11)");

    showMoreLink.addEventListener("click", function (event) {
      event.preventDefault();
      hiddenItems.forEach(item => item.style.display = "flex");
      showMoreLink.style.display = "none";
      hideMoreLink.style.display = "block";
    });

    hideMoreLink.addEventListener("click", function (event) {
      event.preventDefault();
      hiddenItems.forEach(item => item.style.display = "none");
      showMoreLink.style.display = "block";
      hideMoreLink.style.display = "none";
    });
  });
});
